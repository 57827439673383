import React, { useState } from "react"
import { Container, Row } from "react-bootstrap"
import Seo from "../components/seo"
import SideBar from "../components/sideBar/SideBar"
import ProvHeroSection from "../components/banners/providerPage/clinic"
import BenefitsSection from "../sections/provider-section/ProvBenefitsClinic"
import WhyNowSection from "../sections/provider-section/whyNow"
import WhyUsSection from "../sections/provider-section/WhyUsClinic"
import ProviderNav from "../components/homeNav/ProviderNav"
import FaqSection from "../sections/faq/Faq"
import Footer from "../components/homeNav/footer/Footer"
import { dataClinic } from "../sections/provider-section/providerData"

export default function ProviderIndex() {
  const [isOpen, serIsOpen] = useState(false)
  const toggle = () => {
    serIsOpen(!isOpen)
  }
  return (
    <Container fluid style={{ overflow: "hidden" }}>
      <Seo
        title="Online platform and tools for clinics and doctors | Afyabook Pro Kenya"
        description="Get online patients and make a difference in your patients lives with the tools you need to provide care"
        img="https://www.afyabook.com/static/8f4339bfc7163c1851f593c247d64895/39112/knowledge-thumb.webp"
        keywords="Telemedicine, clinics management system kenya, health information management system kenya, software for clinics"
        siteUrl="https://www.afyabook.com/providersMed"
        canonical="https://www.afyabook.com/providersMed"
      ></Seo>
      <Row>
        <SideBar isOpen={isOpen} toggle={toggle} />
        <ProviderNav toggle={toggle} />
        <ProvHeroSection />
        <BenefitsSection />
        <WhyNowSection data={dataClinic} />
        <WhyUsSection />
        <FaqSection />
        <Footer />
      </Row>
    </Container>
  )
}
